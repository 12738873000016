.hero-center {
    min-height: 100vh;
    display: flex;
    align-items: center;
}

.hero-subtext {
    font-family: 'Nunito', sans-serif;
    font-size: 1.5rem;
    color: #fba117 !important;
}

.order-button {
    color: #0f172b !important;
    font-weight: 800;
}

.text-red {
    color: #ce4d4c;
}

.hero-header {
    background: linear-gradient(rgba(15, 23, 43, 0.9), rgba(15, 23, 43, 0.9)),
        url(/img/IMG_9154.jpg);
    background-attachment: fixed;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.hero-filter {
    filter: drop-shadow(0px 0px 2px black);
}
