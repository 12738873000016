.order-button {
    width: 112px;
    height: 40px;
}

.flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}
